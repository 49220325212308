<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Prospeccion" collection="guardar_prospeccion" templateTable="TableProspeccionForm" templateTableModal="TableProspeccionModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import HandleDataForms from '@/components/HandleDataForms.vue'

  export default {
    components: {
      HandleDataForms,
    },
    setup() {
      const store = useStore()
      const mongo = computed(() => store.getters.getMongoURL)
      const database = computed(() => store.getters.getDBDashboard)

      const startDate = computed(() =>
        moment()
          .startOf('week')
          .format('YYYY-MM-DD')
      )
      const endDate = computed(() => moment().format('YYYY-MM-DD'))

      let dataExcel = {
        fields: {
          createdAt: 'Fecha',
          tipo_documento: 'Tipo documento',
          numero_documento: 'Número de documento',
          nombre: 'Nombre',
          apellido_paterno: 'Apellido Paterno',
          email: 'E-mail',
          codigo_area: 'Código de area',
          celular: 'Celular',
          ano_egreso: 'Año de egreso',
          ano_cursado: 'Año cursado',
          carrera: 'Carrera ID',
          carrera_nombre: 'Carrera',
          jornada_nombre: 'Jornada',
          sede: 'Sede ID',
          sede_nombre: 'Sede',
          utm_source: 'Fuente UTM',
          utm_medium: 'Medio UTM',
          utm_campaign: 'Campaña UTM',
          historial: 'Historial'
        },
        query: {
          date_field: "createdAt",
          collection: 'Prospeccion',
          fecha_inicio: startDate.value + ' 00:00:00',
          fecha_fin: endDate.value + ' 23:59:59',
        },
      }

      return {
        mongo,
        dataExcel,
        database
      }
    },
  }
</script>
